<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              {{ isBookingCenter() ? "登記預約中心" : "預約管理" }}
            </b-breadcrumb-item>

            <b-breadcrumb-item v-if="!isBookingCenter()" :to="{ name: 'BookingSelectList' }">
              預約列表
            </b-breadcrumb-item>

            <b-breadcrumb-item active>
              {{ bookingPreset.name }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="d-flex
          flex-column flex-xl-row
          align-items-start align-items-xl-center
          justify-content-between
          mb-4 mb-xl-2">
        <h4 class="col-12 col-xl-4 mb-2 mb-xl-0 font-weight-bold">
          {{ bookingPreset.name }}
          <b-button
            class="mr-1"
            variant="inverse-dark"
            @click="showFormUrl(bookingPreset)"
          >
            <i class="fa fa-link"></i>
          </b-button>
        </h4>
        <div class="d-flex mb-2 mb-md-0">
          <b-button
            class="flex-shrink-0 mb-0 ml-2 btn mr-3"
            variant="primary"
            @click="handleExport"
          >
            <i class="fa fa-plus"></i>匯出
          </b-button>

          <b-input-group class="">
            <b-form-input v-model="query.keyword" placeholder="搜尋" @keyup.enter="handleSearch"></b-form-input>
            <b-input-group-append>
              <b-button @click="handleSearch"><i class="fa fa-search"></i></b-button>
            </b-input-group-append>
          </b-input-group>

          <b-button
            v-b-toggle.collapse-1-inner
            variant="link"
            class="flex-shrink-0 ml-0 ml-xl-1"
          >
            進階搜尋<i class="fa fa-chevron-down"></i>
          </b-button>
        </div>
      </div>
      <div class="d-flex mb-4 mb-xl-2 justify-content-between">
        <div class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          ">
          <b-button v-if="checkPermission([consts.BOOKING_CREATE])" class="flex-shrink-0 mb-2 mb-xl-0" variant="primary"
            :to="isBookingCenter() ? { name: 'BookingCenterBookingCreate', params: { id: bookingPreset.id }, query: { branch_id: query.branch_id } } : { name: 'BookingCreate', params: { id: bookingPreset.id }, query: { branch_id: query.branch_id } }">
            <i class="fa fa-plus"></i>
            新增預約
          </b-button>
        </div>
      </div>

      <b-collapse id="collapse-1-inner" class="mt-2">
        <div class="border p-2">
          <b-form class="">
            <div class="d-flex flex-column flex-xl-row flex-wrap">
              <b-form-group
                label="狀態"
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="7"
                style="width: 50%"
              >
                <b-select
                  class="mr-3"
                  :options="statusOptions"
                  v-model="query.status"
                  :disabled="showLoading"
                ></b-select>
              </b-form-group>
              <b-form-group
                v-if="showServiceSelect"
                label="服務"
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="7"
                style="width: 50%"
              >
                <b-select
                  class="mr-3"
                  :options="serviceOptions"
                  v-model="query.service_id"
                  :disabled="showLoading"
                ></b-select>
              </b-form-group>
              <b-form-group
                v-if="showBranchSelect"
                label="分店"
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="7"
                style="width: 50%"
              >
                <b-select
                  class="mr-3"
                  :options="branchOptions"
                  v-model="query.branch_id"
                  :disabled="showLoading || !this.checkPermission([consts.BOOKING_LIST_BRANCH])"
                ></b-select>
              </b-form-group>
              <b-form-group
                v-if="showStaffSelect"
                label="員工"
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="7"
                style="width: 50%"
              >
                <b-select
                  class="mr-3"
                  :options="staffOptions"
                  v-model="query.staff_id"
                  :disabled="showLoading || !query.branch_id || staffOptions.length <= 1"
                ></b-select>
              </b-form-group>
              <b-form-group
                label="預約時間"
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="7"
                style="width: 50%"
              >
                <DateSearchPicker class="mr-md-2 mr-0" v-model='bookingBetween' startedPlaceholder='開始時間' endedPlaceholder="結束時間" @input="handleDateChange($event, 'booking')" />
              </b-form-group>
              <b-form-group
                label="更新時間"
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="7"
                style="width: 50%"
              >
                <DateSearchPicker class="mr-md-2 mr-0" v-model='updatedBetween' startedPlaceholder='開始時間' endedPlaceholder="結束時間" @input="handleDateChange($event, 'updated')" />
              </b-form-group>
            </div>
            <div class="flex-column">
              <b-button
                v-b-toggle.accordion-2
                variant="primary"
                class="mb-2 mb-xl-0 mr-2"
                @click="handleSearch"
                ><i class="fa fa-search"></i
                > 搜尋 </b-button
              >
              <b-button
                v-b-toggle.accordion-2
                variant="primary"
                class="mb-2 mb-xl-0"
                @click="resetSearch"
                >重置條件</b-button
              >
            </div>
          </b-form>
        </div>
      </b-collapse>

      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table striped hover responsive :items="bookings" :fields="fields">
                <template #cell(title)="data">
                  <RouterLink
                    v-if="checkPermission([consts.BOOKING_EDIT])"
                    :to="isBookingCenter() ? { name: 'BookingCenterBookingEdit', params: { id: bookingPreset.id, booking_id: data.item.id } } : { name: 'BookingEdit', params: { id: bookingPreset.id, booking_id: data.item.id } }">
                  {{ data.value }}
                  </RouterLink>
                  <template v-else>{{ data.value }}</template>
                </template>
                <template #cell()="data">
                  <template v-if="isObject(data.value)">
                    <RouterLink
                      v-if="data.value.type === 'collection_select' && checkPermission([consts.COLLECTION_ITEM_MANAGE])"
                      :to="{ name: 'CollectionItemEdit', params: { id: data.value.collection_id, item_id: data.value.collection_item_id } }">
                      {{ data.value.text }}
                    </RouterLink>
                    <template v-else>{{ data.value.text }}</template>
                  </template>
                  <template v-else-if="data.field.type === 'uploader'">
                    <a v-if="data.value" :href="data.value" target="_blank">（查看檔案）</a>
                    <template v-else>（無檔案）</template>
                  </template>
                  <template v-else-if="data.field.is_dynamic_generated">{{ truncateText(data.value, 15) }}</template>
                  <template v-else>{{ data.value }}</template>
                </template>
                <template #cell(date)="data">
                  <span v-html="displayDate(data.item)"></span>
                </template>
                <template #cell(branch_id)="data">
                  <span>{{ data.item.branch ? data.item.branch.name : '' }}</span>
                </template>
                <template #cell(staff_id)="data">
                  <span>{{ data.item.staff ? data.item.staff.name : '' }}</span>
                </template>
                <template #cell(customer)="data">
                  <b-avatar v-if="data.item.customer && data.item.customer.avatar_url" class="mx-1" size="2em"
                    :src="data.item.customer.avatar_url"></b-avatar>
                  <span v-if="!data.item.customer" class="text-muted">無會員</span>
                  <span v-else>{{ displayCustomer(data.item.customer) }}</span>
                </template>
                <template #cell(actions)="data">
                  <b-button class="mr-1" v-if="checkPermission([consts.BOOKING_LIST_QUOTATION])" variant="inverse-info"
                            :to="{ name: 'BookingCenterBookingPrint', params: { id: bookingPreset.id, booking_id: data.item.id }}">
                    <span class="mdi mdi-printer"></span> 估價單
                  </b-button>
                  <b-dropdown class="mr-1" variant="secondary" v-if="checkPermission([consts.BOOKING_EDIT]) && updateStatusOptions.length > 0">
                    <template slot="button-content">
                      <i class="mdi mdi-menu"></i>
                    </template>
                    <b-dropdown-item
                      v-if="checkPermission([consts.BOOKING_EDIT])"
                      @click="openStatusModal(data.item)"
                    >
                      變更狀態與推播
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-button class="mr-1" v-if="checkPermission([consts.BOOKING_EDIT])" variant="inverse-warning"
                    :to="isBookingCenter() ? { name: 'BookingCenterBookingEdit', params: { id: bookingPreset.id, booking_id: data.item.id } } : { name: 'BookingEdit', params: { id: bookingPreset.id, booking_id: data.item.id } }">
                    <span class="mdi mdi-lead-pencil"></span> 編輯
                  </b-button>
                  <b-button class="mr-1" v-if="checkPermission([consts.BOOKING_DELETE])" variant="inverse-danger"
                    @click="deleteBooking(data.item)">
                    <span class="mdi mdi-delete"></span> 刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <CustomPagination :currentPage="query.page" :totalRows="totalRows" :perPage="query.per_page"
          @page-change="handlePageChange" @per-page-change="handlePerPageChange" />
      </b-card-text>
    </b-card>
    <b-modal
      id="form-status-modal"
      footer-class="d-flex justify-content-center"
      hide-header
    >
      <h5 class="mb-2">變更狀態與推播</h5>
      <h4>狀態</h4>
      <b-select
        class="mr-3"
        id="form-status"
        :options="updateStatusOptions"
        @change="changedStatus = true"
        v-model="selectedItemStatus"
      ></b-select>
      <template #modal-footer>
        <b-button
          size="lg"
          variant="danger"
          @click="$bvModal.hide('form-status-modal'); changedStatus = false"
          >關閉</b-button
        >
        <b-button
          size="lg"
          variant="success"
          @click="changeBookingStatus"
          >{{ changeBookingStatusWording }}</b-button
        >
      </template>
    </b-modal>
    <b-modal
      id="form-url-modal"
      footer-class="d-flex justify-content-center"
      hide-header
    >
      <h5 class="mb-2">填寫網址</h5>
      <b-textarea
        id="form-url"
        v-b-tooltip.click="'已複製'"
        v-model="formUrl"
        @click="copyFormUrl('#form-url')"
      ></b-textarea>
      <template v-if="!!recordUrl">
        <h5 class="my-2">填寫紀錄</h5>
        <b-textarea
          id="record-url"
          v-b-tooltip.click="'已複製'"
          v-model="recordUrl"
          @click="copyFormUrl('#record-url')"
        ></b-textarea>
      </template>
      <template #modal-footer>
        <b-button
          size="lg"
          variant="danger"
          @click="$bvModal.hide('form-url-modal')"
          >關閉</b-button
        >
      </template>
    </b-modal>
  </div>

</template>

<script>
import * as consts from "@/consts";
import PermissionChecker from '@/utils/PermissionChecker';
import {format} from "date-fns";
import bookingApi from "@/apis/booking";
import branchApi from "@/apis/branch";
import collectionApi from "@/apis/collection";
import { mapState, mapGetters } from "vuex";
import DateSearchPicker from "@/components/Page/Dashboard/DateSearchPicker.vue";
import moment from "moment";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { paginationMixin } from "@/mixins/pagination";
import Collection from "@/models/Collection.model.js";
import truncateText from "@/utils/truncateText"
import { updateQueryFromRoute, updateUrlWithoutReload } from "@/utils/updateUrl";
import generateLiffUrl from "@/mixins/Dashboard/liffUrlHelper";

export default {
  components: { DateSearchPicker, CustomPagination },
  mixins: [paginationMixin, generateLiffUrl],
  data() {
    return {
      consts: consts,
      showLoading: false,
      fieldsInit: false,
      statusOptions: [],
      selectedItemStatus: null,
      selectedBooking: {},
      statusChanged: false,
      fields: [
        {
          key: 'updated_at',
          label: '更新時間',
          sortable: true,
          formatter: (value) => {
            return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
          },
        },
        { key: 'actions', label: '管理' },
      ],
      bookings: [],
      bookingPreset: {
        id: null,
        name: null,
        config: null,
      },
      totalRows: 0,
      collections: [],
      initialized: false,
      bookingBetween: {
        start_at: null,
        end_at: null,
      },
      updatedBetween: {
        start_at: null,
        end_at: null,
      },
      query: {
        start_at: null,
        end_at: null,
        per_page: 10,
        page: 1,
        keyword: '',
        branch_id: null,
        staff_id: null,
        service_id: null,
        status: null,
        updated_start_at: null,
        updated_end_at: null,
      },
      formUrl: null,
      recordUrl: null,
      staffOptions: [],
    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters('general', [
      'myBranches',
      'currentBranch'
    ]),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
    updateStatusTitle() {
      const fieldId = this.bookingPreset.config?.booking_mapping?.bookings?.status;
      console.log(fieldId)
      const field = this.bookingPreset.config?.fields.find((f) => f._id === fieldId );
      if (field) {
        return  field.config?.title ?? field.title ;
      } else {
        return []
      }
    },
    updateStatusOptions() {
      const fieldId = this.bookingPreset.config?.booking_mapping?.bookings?.status;
      console.log(fieldId)
      const field = this.bookingPreset.config?.fields.find((f) => f._id === fieldId );
      if (field) {
        return field.config?.options ?? field.options;
      } else {
        return []
      }
    },
    serviceOptions() {
      const collections = this.collections.find((col) => col.id == this.bookingPreset.config?.service?.collection_id);
      const fieldId = this.bookingPreset.config?.service?.title_item_id
      return collections?.items?.map((item) => {
        let field = item.data.find(d => d.field_id == fieldId)
        return {
          value: item.id,
          text: field.value
        }
      }) ?? []
    },
    branchOptions() {
      return this.myBranches
        .map(branch => {
          let branchName = `${branch.branch_code} ${branch.name}`
          return {
            value: branch.id,
            text: branchName
          }
        })
    },
    showBranchSelect() {
      return this.bookingPreset?.config?.branch?.field_id
    },
    showStaffSelect() {
      return this.bookingPreset?.type == 'wizard' && this.bookingPreset?.config?.enabled_selected_staff
    },
    showServiceSelect() {
      return this.bookingPreset?.type == 'wizard' && this.bookingPreset.config?.service?.collection_id
    },
    changeBookingStatusWording() {
      // this.selectedBooking.status 原始的狀態
      if (this.bookingPreset.config.status_change_events) {
        if (this.bookingPreset.config.status_change_events.find((event) => event.booking_status == this.selectedItemStatus )) {
          if (this.selectedBooking.status == this.selectedItemStatus) {
            return '重新推播'
          } else {
            return '變更狀態與推播'
          }
        } else {
          return '變更狀態'
        }
      }
      return '變更狀態'
    }
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrlWithoutReload(this.query, this.$store, this.$route)
        this.fetchBookings();
      },
    },
    "query.branch_id": {
      async handler(val) {
        if (val) {
          await this.fetchStaff();
        } else {
          this.staffOptions = [
            {
              value: null,
              text: '全部'
            }
          ]
        }
      },
    },
  },
  async mounted() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
    this.bookingPreset.id = this.$route.params.id;
    if (!this.bookingPreset.id) {
      await this.$swal("錯誤", "找不到預約設定檔", "error");
      return;
    }
    await this.fetchBookingPreset();
    if (this.showBranchSelect) {
      if (this.currentBranch) {
        this.query.branch_id = this.currentBranch.id
      }
    }
    this.fetchBookings();
    this.getStatusOptions();
    this.initialized = true
  },
  methods: {
    truncateText,
    async submitUpdateStatus() {
      try {
        this.showLoading = true
        await bookingApi.updateBookingStatus(this.bookingPreset.id, this.selectedBooking.id, {
          field_id: this.bookingPreset.config?.booking_mapping?.bookings?.status,
          title: this.updateStatusTitle,
          status: this.selectedItemStatus
        })
        this.$swal('更新成功', '', 'success')
        this.statusChanged = true
        await this.fetchBookings()
      } catch (error) {
        console.error(error)
        this.$swal('更新失敗', '', 'error')
      } finally {
        this.showLoading = false
      }
    },
    changeBookingStatus() {
      this.statusChanged = false
      this.submitUpdateStatus()
      this.$bvModal.hide('form-status-modal')
    },
    openStatusModal(item) {
      this.selectedItemStatus = item.status
      console.log(item.status)
      this.selectedBooking = item
      this.$bvModal.show("form-status-modal");
    },
    async getStatusOptions() {
      const { data } = await bookingApi.getBookingOptions(this.bookingPreset.id)
      this.statusOptions = data.status_mapping.map(status => {
        return {
          value: status,
          text: status
        }
      })
      this.statusOptions.push({
        value: null,
        text: '全部'
      })
    },
    async fetchStaff() {
      const { data } = await branchApi.getBranchStaffs(this.query.branch_id)
      this.staffOptions = data.data.map(staff => {
        return {
          value: staff.id,
          text: staff.name
        }
      })

      this.staffOptions.unshift({
        value: null,
        text: '全部'
      })
    },
    isBookingCenter() {
      return this.$route.name.includes("BookingCenter");
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    displayCustomer(customer) {
      if (!customer) {
        return "無會員";
      }
      return customer.name + (customer.mobile_phone ? ` ${customer.mobile_phone}` : '');
    },
    displayDate(item) {
      // 檢查 start_at 和 end_at 並轉換為 Date 對象
      const startAt = item.start_at ? new Date(item.start_at) : null;
      const endAt = item.end_at ? new Date(item.end_at) : null;

      // 格式化日期為 "YYYY-MM-DD HH:mm"
      const formatDateTime = (date) => {
        if (!date) return ''; // 如果日期為 null，返回空字串

        return date.toLocaleString('zh-TW', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        });
      };

      const formattedStartAt = startAt ? formatDateTime(startAt) : ''; // 格式化 start_at
      const formattedEndAt = endAt ? formatDateTime(endAt) : '';       // 格式化 end_at

      // 返回格式化后的日期，使用 <br> 換行
      return `${formattedStartAt} ${formattedStartAt && formattedEndAt ? "~<br>" : ""} ${formattedEndAt}`;
    },
    async fetchBookingPreset() {
      try {
        this.showLoading = true;
        const response = await bookingApi.getBookingPreset(this.bookingPreset.id);
        this.bookingPreset = response.data.data;

        if (!this.bookingPreset.config?.fields || this.bookingPreset.config.fields.length === 0) {
          this.$swal("提醒", "此預約尚未設定欄位。", "warning");
          // this.$router.push({
          //   name: "BookingPresetList",
          // });
        }

        let newField = [];

        for (let i = 0; i < this.bookingPreset.config.fields.length; i++) {
          const field = this.bookingPreset.config.fields[i];

          if (field.config?.list_hidden === true || field.list_hidden === true) {
            continue;
          }

          let fieldConfig = {
            key: field._id ?? field.config._id,
            label: field.title ?? field.config.title,
            sortable: true,
            type: field.type,
            is_dynamic_generated: true,
          };
          const collectionId = field.collection_id ?? field.config?.collection_id

          if (["collection_select", "collection_carousel"].includes(field.type) && !this.collections.find((collection) => collection.id === collectionId)) {
            let collectionResponse = await collectionApi.getCollection(collectionId);
            this.collections.push(collectionResponse.data.data);
          }

          newField.push(fieldConfig);
        }

        if (this.bookingPreset.type == 'wizard') {
          if (this.bookingPreset.config?.enabled_selected_branch) {
            newField.push({
              key: 'branch_id',
              label: '分店',
              index: 3,
            });
          }

          if (this.bookingPreset.config?.enabled_selected_staff) {
            newField.push({
              key: 'staff_id',
              label: '員工',
              index: 4
            });
          }

          if (this.bookingPreset.config?.service?.collection_id) {
            if (!this.collections.find((col) => col.id == this.bookingPreset.config?.service?.collection_id)) {
              let collectionResponse = await collectionApi.getCollection(this.bookingPreset.config?.service?.collection_id);
              this.collections.push(collectionResponse.data.data);
            }
            newField.push({
              key: 'service_id',
              label: '服務',
              index: 5,
              formatter: (value) => {
                if (!value) {
                  return ''
                }
                const collections = this.collections.find((col) => col.id == this.bookingPreset.config?.service?.collection_id);
                const fieldId = this.bookingPreset.config?.service?.title_item_id
                const items = collections?.items?.filter((item) => value.includes(item.id));
                if (items) {
                  return items.map((item) => {
                    const i = item.data.find((data) => data.field_id == fieldId)
                    return i.value || i.text ;
                  }).join(', ');
                }
                return '';
              }
            });
          }
        }

        this.fields.unshift(...newField);
        this.fields.unshift({key: 'customer',label: '預約會員'});
        this.fields.unshift( {key: 'date',label: '起迄時間'});
        this.fields.unshift({ key: 'title', label: '標題', sortable: false });

        // NOTE: relocate table fields
        this.fields.filter(field => Object.keys(field).includes('index'))
          .map(field => {
            this.fields.splice(this.fields.indexOf(field), 1);
            this.fields.splice(field.index, 0, field);
          })
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取設定錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async fetchBookings() {
      try {
        this.showLoading = true;
        const params = { ...this.query };
        const response = await bookingApi.getBookings(this.bookingPreset.id, params);

        this.bookings = response.data.data.map((booking) => {
          let newBooking = {
            ...booking
          };
          for (let i = 0; i < booking.data.length; i++) {
            let data = booking.data[i];

            // 如果遇到陣列型的資料，將陣列轉為字串
            if (Array.isArray(data.value)) {
              if (typeof data.value[0] === 'object') {
                if (data.value[0] !== null && ['collection_select', 'collection_carousel'].includes(data.value[0].type)) {
                  for (let j = 0; j < data.value.length; j++) {
                    console.log(data.value[j])
                    const collection = this.collections.find((collection) => collection.id === data.value[j].collection_id);
                    if (!collection) {
                      continue;
                    }
                    try {
                      const collectionModel = new Collection(collection)
                      data.value[j].text = collectionModel.textForOption(data.value[j].collection_item_id)
                    } catch (error) {
                      // 抓不到代表Collection Item已被刪除，改使用Data原先內容並提示（已刪除）字眼
                      data.value[j].text = `${data.value[j].text}(已刪除)`
                    }
                  }
                  newBooking[data.field_id] = data.value.map((item) => item.text).join(', ');
                  data.value = data.value.map((item) => item.text).join(', ');
                  continue;
                }
              } else {
                newBooking[data.field_id] = data.value.join(', ');
                continue;
              }
            }

            // 如果遇到 collection_select 或 collection_carousel，就把 collection_items 的值取出來
            // 1211 之後會使用[Object]格式 下方這個會棄用 但是兼容
            if (typeof data.value === 'object' && data.value !== null && ['collection_select', 'collection_carousel'].includes(data.value.type)) {
              const collection = this.collections.find((collection) => collection.id === data.value.collection_id);
              if (!collection) {
                continue;
              }
              const collectionModel = new Collection(collection)
              try {
                data.value.text = collectionModel.textForOption(data.value.collection_item_id)
                data.value = data.value.text
              } catch (error) {
                // 抓不到代表Collection Item已被刪除，改使用Data原先內容並提示（已刪除）字眼
                data.value.text = `${data.value.text}(已刪除)`
              }
            }

            newBooking[data.field_id] = data.value;
          }
          const titleField = this.bookingPreset?.config?.booking_mapping?.bookings?.title
          if (titleField == 'staff_title') {
            newBooking['title'] = booking.staff?.name || booking.staff_id
          } else if (titleField == 'branch_title') {
            newBooking['title'] = booking.branch?.name || booking.branch_id
          } else if (titleField == 'booking_number') {
            newBooking['title'] = booking.booking_number
          } else if (titleField == 'service_title') {
            if (booking.service_id) {
              const collections = this.collections.find((col) => col.id == this.bookingPreset.config?.service?.collection_id);
              const fieldId = this.bookingPreset.config?.service?.title_item_id
              const items = collections?.items?.filter((item) => booking.service_id.includes(item.id));
              if (items) {
                newBooking['title'] = items.map((item) => {
                  const i = item.data.find((data) => data.field_id == fieldId)
                  return i.value || i.text;
                }).join(', ');
              } else {
                newBooking['title'] = ''
              }
            } else {
              newBooking['title'] = ''
            }
          } else {
            const title = booking.data.find((data) => data.field_id === titleField)?.value
            newBooking['title'] = title || booking?.customer?.name || booking?.customer_id || booking.id
          }

          return newBooking;
        });

        this.totalRows = response.data.meta.total;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料集項目錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async deleteBooking(booking) {
      try {
        this.showLoading = true;
        const result = await this.$swal({
          title: "刪除預約",
          text: `是否要將此預約刪除？`,
          type: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "取消",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "移除",
          reverseButtons: true,
        })

        if (!result.value) {
          return
        }

        await bookingApi.deleteBooking(this.bookingPreset.id, booking.id);
        this.$swal('刪除成功', '', 'success');
        await this.fetchBookings();
      } catch (error) {
        console.error(error);
        this.$swal('刪除失敗', '', 'error');
      } finally {
        this.showLoading = false;
      }
    },
    isObject(field) {
      return typeof field === 'object' && field !== null;
    },
    handleDateChange(between, type) {
      console.log(between)
      const startAt = (between.start_at) ? moment(between.start_at).format("YYYY-MM-DD 00:00:00") : null;
      const endAt = (between.end_at) ? moment(between.end_at).format("YYYY-MM-DD 23:59:59") : null;

      if (type === 'booking') {
        this.query.start_at = startAt;
        this.query.end_at = endAt;
      } else {
        this.query.updated_start_at = startAt;
        this.query.updated_end_at = endAt;
      }
    },
    showFormUrl(bookingPreset) {
      this.formUrl = this.generateLiffUrl(`booking/form/${bookingPreset.id}`);
      this.recordUrl = this.generateLiffUrl(`booking/${bookingPreset.id}`);
      this.$bvModal.show("form-url-modal");
    },
    copyFormUrl(id) {
      const inputText = document.querySelector(id);
      inputText.select();
      document.execCommand("copy");
    },
    resetSearch() {
      this.query = {
        start_at: null,
        end_at: null,
        per_page: 10,
        page: 1,
        keyword: '',
        branch_id: null,
        staff_id: null,
        service_id: null,
        status: null,
        updated_start_at: null,
        updated_end_at: null,
      }
      this.bookingBetween = {
        start_at: null,
        end_at: null,
      }
      this.updatedBetween = {
        start_at: null,
        end_at: null,
      }
    },
    handleSearch() {
      if (!this.initialized) return;
      const currentPage = parseInt(this.query.page, 10);

      if (currentPage === 1) {
        updateUrlWithoutReload(this.query, this.$store, this.$route);
        this.fetchBookings();
      } else {
        this.query.page = 1;
      }
    },
    async handleExport() {
      try {
        this.showLoading = true;

        const params = { ...this.query };

        await bookingApi.exportBookings(this.bookingPreset.id, params);

        this.$swal({
          title: "成功",
          text: "可至“我的主控台”查看紀錄及下載檔案",
          showCancelButton: true,
          cancelButtonText: '確認',
          type: "success",
          confirmButtonText: "前往查看",
        }).then((result) => {
          if (result.value) {
            this.$router.push({
              name: "MyConsoleDownloadFileList",
              params: { org_code: this.$route.params.org_code },
            });
          }
        });
      } finally {
        this.showLoading = false;
      }
    },
  }
}
</script>
<style scoped>
.row-searchbar {
  width: 80%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
</style>
